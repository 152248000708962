import { ROLES, setRoles } from "./role.config";

export const DynamicAsideMenuConfig = {
  items: [
    { section: 'Calendario', permission: setRoles([ROLES.ALUMNO, ROLES.PROFESOR, ROLES.ADMINISTRADOR, ROLES.RECEPCIONISTA, ROLES.APODERADO]) },
    {
      title: 'Calendario',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/schedules-available/students/elections',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.ALUMNO])
    },
    {
      title: 'Calendario',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/schedules-available/parents',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.APODERADO])
    },
    {
      title: 'Calendario',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/users/subjects',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.ADMINISTRADOR])
    },
    {
      title: 'Clases',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Home/Book.svg',
      page: '/calendar/class',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.ALUMNO, ROLES.PROFESOR, ROLES.RECEPCIONISTA]),
    },
    {
      title: 'Guías',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Home/Book.svg',
      page: '/guides/gallery',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.ALUMNO, ROLES.PROFESOR]),
    },
    {
      title: 'Solicitudes Particulares',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Communication/Send.svg',
      page: '/calendar/private',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.ALUMNO, ROLES.PROFESOR, ROLES.ADMINISTRADOR]),
    },
    { section: 'Exámenes', permission: setRoles([ROLES.APODERADO, ROLES.ALUMNO, ROLES.PROFESOR, ROLES.ADMINISTRADOR]), coordinador: setRoles([ROLES.VENDEDOR]) },
    {
      title: 'Exámenes',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Home/Book-open.svg',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.ADMINISTRADOR]),
      coordinador: setRoles([ROLES.VENDEDOR]),
      submenu: [
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
          page: '/exams/grades', title: 'Calificadores'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
          page: '/exams', title: 'Exámenes'
        },

      ]
    },
    {
      title: 'Estadísticas',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Shopping/Chart-line1.svg',
      page: '/exams/stats/professor',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.PROFESOR]),
    },
    {
      title: 'Exámenes',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Code/Puzzle.svg',
      page: '/exams/students',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.ALUMNO]),
    },
    {
      title: 'Estadísticas',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Shopping/Chart-line1.svg',
      page: '/exams/stats',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.APODERADO, ROLES.ALUMNO]),
    },

    { section: 'Usuarios', permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR, ROLES.COORDINADORGENERAL]) },
    {
      title: 'Usuarios',
      bullet: 'dot',
      icon: 'flaticon2-list-2',
      svg: './assets/media/svg/icons/General/User.svg',
      root: false,
      translate: 'MENU.DASHBOARD',
      permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
      submenu: [
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
          page: '/users/parents', title: 'Apoderados'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
          page: '/users/students', title: 'Alumnos'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/users/professors', title: 'Profesores'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/users/sellers', title: 'Vendedores'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/users/admins', title: 'Administradores'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/users/secretaries', title: 'Recepcionistas'
        }
      ]
    },
    {
      title: 'Cursos',
      bullet: 'dot',
      icon: 'flaticon2-list-2',
      svg: './assets/media/svg/icons/General/User.svg',
      root: true,
      translate: 'MENU.DASHBOARD',
      permission: setRoles([ROLES.ADMINISTRADOR, ROLES.COORDINADORGENERAL]),
      page: '/users/courses',
    },

    { section: 'Asistencias', permission: setRoles([ROLES.ADMINISTRADOR, ROLES.RECEPCIONISTA, ROLES.APODERADO]) },
    {
      title: 'Estudiantes',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/General/User.svg',
      page: '/assistances/admin',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.ADMINISTRADOR, ROLES.RECEPCIONISTA]),
    },
    {
      title: 'Hijos',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/General/User.svg',
      page: '/assistances/parents',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.APODERADO]),
    },
    {
      title: 'Profesores',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/assistances/professors',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: setRoles([ROLES.ADMINISTRADOR, ROLES.RECEPCIONISTA]),
    },


    { section: 'Contabilidad', permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR, ROLES.PROFESOR, ROLES.APODERADO]) },
    {
      title: 'Finanzas',
      bullet: 'dot',
      icon: 'flaticon2-list-2',
      svg: './assets/media/svg/icons/Shopping/Dollar.svg',
      root: true,
      permission: setRoles([ROLES.ADMINISTRADOR, ROLES.PROFESOR, ROLES.APODERADO, ROLES.VENDEDOR]),
      page: '',
      submenu: [
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/block-prices', title: 'Precios'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/discounts', title: 'Descuentos'
        },
        {
          title: 'Ventas',
          bullet: 'dot',
          root: true,
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
          page: '',
          submenu: [
            {
              permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
              page: '/sales/contact', title: 'Contactos'
            },
            {
              permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
              page: '/sales/online', title: 'Online'
            },
            {
              permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
              page: '/sales/done', title: 'Realizadas'
            },
          ],
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
          page: '/payments', title: 'Pagos de Apoderados'
        },
        {
          permission: setRoles([ROLES.APODERADO]),
          page: '/payments/parents', title: 'Pagos'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.PROFESOR]),
          page: '/payouts', title: 'Cobros'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.VENDEDOR]),
          page: '/debts', title: 'Deudas'
        },
      ]
    },

    { section: 'Configuración de Calendario', permission: setRoles([ROLES.ADMINISTRADOR, ROLES.RECEPCIONISTA]) },
    {
      title: 'Configuración',
      bullet: 'dot',
      icon: 'flaticon2-list-2',
      svg: './assets/media/svg/icons/General/Settings-2.svg',
      root: true,
      permission: setRoles([ROLES.ADMINISTRADOR, ROLES.RECEPCIONISTA, ROLES.VENDEDOR]),
      page: '/users',
      submenu: [
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/campus', title: 'Sedes'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/rooms', title: 'Salas'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/course-types', title: 'Modalidades'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/courses', title: 'Año Académico'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.RECEPCIONISTA]),
          page: '/categories', title: 'Categorias'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.RECEPCIONISTA]),
          page: '/subjects', title: 'Ramos'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.RECEPCIONISTA]),
          page: '/topics', title: 'Temas'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR, ROLES.RECEPCIONISTA]),
          page: '/guides', title: 'Guías'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/sections', title: 'Secciones'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/hours', title: 'Bloque Horario'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/schedules', title: 'Calendario'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/schedules-available/holidays', title: 'Calendario Feriados'
        },
        {
          permission: setRoles([ROLES.RECEPCIONISTA, ROLES.VENDEDOR]),
          page: '/schedules-available/admin', title: 'Calendario'
        },
        {
          permission: setRoles([ROLES.ADMINISTRADOR]),
          page: '/settings', title: 'Configuraciones Especiales'
        },
      ]
    },
  ]
};
